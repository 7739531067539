<template>
  <div class="about-container">
    <!-- banner_partner -->
    <div class="banner_partner">
      <i
        style="
          background-image: url(https://img.cdn.wxzhida.cn/shzd-web-site/images/banner_partner.jpg);
        "
      ></i>
      <!-- <i style="background-image: url(https://img.cdn.wxzhida.cn/shzd-web-site/images/banner_partner_l.png);" class="l a-fadeinL"></i>
    <i style="background-image: url(https://img.cdn.wxzhida.cn/shzd-web-site/images/banner_partner_r.png);" class="r a-fadeinR"></i> -->
      <div class="margin width1480 hidden a-fdB">
        <dd>挚友合作</dd>
        <dl>
          <p>
            随着新能源产业的飞速发展，挚达将持续开放各项能力，与各行业合作伙伴携手，打造共生共赢的智慧生态体系，
          </p>
          <p>目前是全国最大的家庭/社区充电产品和服务提供商</p>
        </dl>
      </div>
    </div>
    <!-- banner_partner en -->
    <!-- partner_t -->
    <div id="p1">
      <div class="margin width1480 hidden">
        <ul class="partner_t">
          <li>
            <ol>
              <img
                src="https://img.cdn.wxzhida.cn/shzd-web-site/images/partner_t1.jpg"
              />
            </ol>
            <!-- <dt><i id="p_s1">20.5</i><span>%</span></dt> -->
            <dd>产品力</dd>
            <dl>
              <p class="margin-right-40">
                于2021.1-2023.9期间，我们全球已累计出货90万台家用电动汽车充电桩及在中国已累计出货80万台家用电动汽车充电桩。按家用电动汽车充电桩销量计，我们的中国市场占有率达20.5%，全球市场占有率达到12.2%。
              </p>
            </dl>
          </li>
          <li>
            <ol>
              <img
                src="https://img.cdn.wxzhida.cn/shzd-web-site/images/partner_t2.jpg"
              />
            </ol>
            <!-- <dt><i id="p_s2">80</i>%</dt> -->
            <dd>数字力</dd>
            <dl>
              <p class="margin-right-40">
                我们的平台实现了安装及售后网络的管理数字化，支持我们的共享充电服务，更重要的是，巩固了我们的“车到户”(V2H)及“车能互动”(V2E)能力，以支持我们不断增长的各种数字能源管理服务。
              </p>
            </dl>
          </li>
          <li>
            <ol>
              <img
                src="https://img.cdn.wxzhida.cn/shzd-web-site/images/partner_t3.jpg"
              />
            </ol>
            <!-- <dt><i id="p_s3">100</i><span>万</span></dt> -->
            <dd>制造力</dd>
            <dl class="margin-right-40">
              在中国以外地区，凭借我们在中国建设及运营工厂的成功经验和专业知识，我们于2023年9月启动了泰国工厂的筹建工作，并预期于2024年第二季度开始营运。
            </dl>
          </li>
          <li>
            <ol>
              <img
                src="https://img.cdn.wxzhida.cn/shzd-web-site/images/partner_t4.jpg"
              />
            </ol>
            <!-- <dt><i id="p_s4">360</i></dt> -->
            <dd>品牌力</dd>
            <dl class="margin-right-40">
              根据弗若斯特沙利文的资料，按2022年及截至2023年9月30日止九个月电商平台上家用电动汽车充电桩的零售额计，我们是前三大品牌之一。
            </dl>
          </li>
          <!-- <li>
            <ol><img src="https://img.cdn.wxzhida.cn/shzd-web-site/images/partner_t5.jpg" /></ol>
            <dt><i id="p_s5">10</i><span>万</span></dt>
            <dd>订单交付率全国第一</dd>
            <dl>
                <p>2021年全年，私人充电桩安装订单交付量</p>
                <p>突破10万单</p>
            </dl>
        </li> -->
          <li>
            <ol>
              <img
                src="https://img.cdn.wxzhida.cn/shzd-web-site/images/partner_t6.jpg"
              />
            </ol>
            <!-- <dt><i id="p_s6">140</i></dt> -->
            <dd>服务力</dd>
            <dl>
              <p class="margin-right-40">
                截至2023年9月30日，已覆盖全国超过360个城市。在全国各地共有超过140多家下属安装服务商。
              </p>
            </dl>
          </li>
        </ul>
      </div>
    </div>
    <!-- partner_hzhbsq -->
    <div id="p2">
      <div class="partner_hzhbsq">
        <i class="md" id="p"></i>
        <div class="margin width1480 hidden">
          <div class="tit">
            <!--<ol>合作伙伴申请</ol>-->
            <dd>加入挚达，逐梦再出发</dd>
          </div>
          <!-- <div class="hzhbsq_n">
            <span class="l"><i>01</i>选择合作伙伴类型</span><i class="x"></i>
            <span class="l"><i>02</i>提交合作申请</span><i class="x"></i>
            <span class="l"><i>03</i>伙伴资质审核</span><i class="x"></i>
            <span class="r"><i>04</i>成为挚达合作伙伴</span>
        </div> -->
          <ul>
            <li class="fl">
              <div class="bg">
                <div>
                  <ol>
                    <img
                      src="https://img.cdn.wxzhida.cn/shzd-web-site/images/hzhbsq1.png"
                    />
                  </ol>
                  <dd>安装业务加盟</dd>
                  <dl>
                    <p>对挚达的客户提供安装、售后服务等相关的运维服务</p>
                    <p>400-6596-580</p>
                  </dl>
                </div>
              </div>
            </li>
            <li class="fr">
              <div class="bg">
                <div>
                  <ol>
                    <img
                      src="https://img.cdn.wxzhida.cn/shzd-web-site/images/hzhbsq2.png"
                    />
                  </ol>
                  <dd>产品代理加盟</dd>
                  <dl>有意者联系：lsqd@shzhida.com</dl>
                </div>
              </div>
            </li>
            <li class="fl">
              <div class="bg">
                <div>
                  <ol>
                    <img
                      src="https://img.cdn.wxzhida.cn/shzd-web-site/images/hzhbsq2.png"
                    />
                  </ol>
                  <dd>海外安装业务加盟</dd>
                  <dl>
                    <p>对挚达的海外客户提供安装、售后服务等相关的运维服务</p>
                    <p>联系方式：eva.zhang@shzhida.com</p>
                  </dl>
                </div>
              </div>
            </li>
            <!-- <li>
                <div class="bg">
                    <ol><img src="https://img.cdn.wxzhida.cn/shzd-web-site/images/hzhbsq3.png" /></ol>
                    <dd>产品售后</dd>
                    <dl>原厂技服全程提供技术支持服务</dl>
                    <a href="">立即申请 ></a>
                </div>
            </li>
            <li>
                <div class="bg">
                    <ol><img src="https://img.cdn.wxzhida.cn/shzd-web-site/images/hzhbsq4.png" /></ol>
                    <dd>共享场站运营</dd>
                    <dl>推广及销售挚达产品及服务</dl>
                    <a href="">立即申请 ></a>
                </div>
            </li>
            <li>
                <div class="bg">
                    <ol><img src="https://img.cdn.wxzhida.cn/shzd-web-site/images/hzhbsq5.png" /></ol>
                    <dd>充电场站运维</dd>
                    <dl>推广及销售挚达产品及服务</dl>
                    <a href="">立即申请 ></a>
                </div>
            </li>
            <li class="f">
                <div class="bg">
                    <div>
                        <ol><img src="https://img.cdn.wxzhida.cn/shzd-web-site/images/hzhbsq6.png" /></ol>
                        <dl>期待更多...</dl>
                    </div>
                </div>
            </li> -->
          </ul>
        </div>
      </div>
    </div>
    <!-- partner_hzhb -->
    <div id="p3">
      <div class="margin width1480 hidden partner_hzhb">
        <div class="tit">
          <dd>优秀合作伙伴</dd>
          <!-- <dl>合作车企70余家，随车配建市场占有率20.5%</dl> -->
        </div>
        <div class="tabBar">
          <!-- <div class="hd">
            <ul>
              <li class="on"></li>
              <li></li>
              <li></li>
            </ul>
          </div> -->
          <div class="bd">
            <div class="tempWrap">
              <div class="conWrap">
                <div class="con">
                  <dl>
                    <i
                      ><img
                        src="https://img.cdn.wxzhida.cn/shzd-web-site/fact-icons/logo-01.png"
                    /></i>
                  </dl>
                  <dl>
                    <i
                      ><img
                        src="https://img.cdn.wxzhida.cn/shzd-web-site/fact-icons/logo-02.png"
                    /></i>
                  </dl>
                  <dl>
                    <i
                      ><img
                        src="https://img.cdn.wxzhida.cn/shzd-web-site/fact-icons/logo-03.png"
                    /></i>
                  </dl>
                  <dl>
                    <i
                      ><img
                        src="https://img.cdn.wxzhida.cn/shzd-web-site/fact-icons/logo-04.png"
                    /></i>
                  </dl>
                  <dl>
                    <i
                      ><img
                        src="https://img.cdn.wxzhida.cn/shzd-web-site/fact-icons/logo-05.png"
                    /></i>
                  </dl>

                  <dl>
                    <i
                      ><img
                        src="https://img.cdn.wxzhida.cn/shzd-web-site/fact-icons/logo-06.png"
                    /></i>
                  </dl>
                  <dl>
                    <i
                      ><img
                        src="https://img.cdn.wxzhida.cn/shzd-web-site/fact-icons/logo-07.png"
                    /></i>
                  </dl>
                  <dl>
                    <i
                      ><img
                        src="https://img.cdn.wxzhida.cn/shzd-web-site/fact-icons/logo-08.png"
                    /></i>
                  </dl>

                  <dl>
                    <i
                      ><img
                        src="https://img.cdn.wxzhida.cn/shzd-web-site/fact-icons/logo-09.png"
                    /></i>
                  </dl>
                  <dl>
                    <i
                      ><img
                        src="https://img.cdn.wxzhida.cn/shzd-web-site/fact-icons/logo-10.png"
                    /></i>
                  </dl>
                  <dl>
                    <i
                      ><img
                        src="https://img.cdn.wxzhida.cn/shzd-web-site/fact-icons/logo-11.png"
                    /></i>
                  </dl>
                  <dl>
                    <i
                      ><img
                        src="https://img.cdn.wxzhida.cn/shzd-web-site/fact-icons/logo-12.png"
                    /></i>
                  </dl>
                  <dl>
                    <i
                      ><img
                        src="https://img.cdn.wxzhida.cn/shzd-web-site/fact-icons/logo-13.png"
                    /></i>
                  </dl>
                  <dl>
                    <i
                      ><img
                        src="https://img.cdn.wxzhida.cn/shzd-web-site/fact-icons/logo-14.png"
                    /></i>
                  </dl>
                  <dl>
                    <i
                      ><img
                        src="https://img.cdn.wxzhida.cn/shzd-web-site/fact-icons/logo-15.png"
                    /></i>
                  </dl>
                  <dl>
                    <i
                      ><img
                        src="https://img.cdn.wxzhida.cn/shzd-web-site/fact-icons/logo-16.png"
                    /></i>
                  </dl>

                  <dl>
                    <i
                      ><img
                        src="https://img.cdn.wxzhida.cn/shzd-web-site/fact-icons/logo-17.png"
                    /></i>
                  </dl>
                  <dl>
                    <i
                      ><img
                        src="https://img.cdn.wxzhida.cn/shzd-web-site/fact-icons/logo-18.png"
                    /></i>
                  </dl>
                  <dl>
                    <i
                      ><img
                        src="https://img.cdn.wxzhida.cn/shzd-web-site/fact-icons/logo-19.png"
                    /></i>
                  </dl>
                  <dl>
                    <i
                      ><img
                        src="https://img.cdn.wxzhida.cn/shzd-web-site/fact-icons/logo-20.png"
                    /></i>
                  </dl>
                  <dl>
                    <i
                      ><img
                        src="https://img.cdn.wxzhida.cn/shzd-web-site/fact-icons/logo-21.png"
                    /></i>
                  </dl>
                  <dl>
                    <i
                      ><img
                        src="https://img.cdn.wxzhida.cn/shzd-web-site/fact-icons/logo-22.png"
                    /></i>
                  </dl>
                  <dl>
                    <i
                      ><img
                        src="https://img.cdn.wxzhida.cn/shzd-web-site/fact-icons/logo-23.png"
                    /></i>
                  </dl>

                  <dl>
                    <i
                      ><img
                        src="https://img.cdn.wxzhida.cn/shzd-web-site/fact-icons/logo-24.png"
                    /></i>
                  </dl>
                  <dl>
                    <i
                      ><img
                        src="https://img.cdn.wxzhida.cn/shzd-web-site/fact-icons/logo-25.png"
                    /></i>
                  </dl>
                  <dl>
                    <i
                      ><img
                        src="https://img.cdn.wxzhida.cn/shzd-web-site/fact-icons/logo-26.png"
                    /></i>
                  </dl>

                  <dl>
                    <i
                      ><img
                        src="https://img.cdn.wxzhida.cn/shzd-web-site/fact-icons/logo-27.png"
                    /></i>
                  </dl>
                  <dl>
                    <i
                      ><img
                        src="https://img.cdn.wxzhida.cn/shzd-web-site/fact-icons/logo-28.png"
                    /></i>
                  </dl>
                  <dl>
                    <i
                      ><img
                        src="https://img.cdn.wxzhida.cn/shzd-web-site/fact-icons/logo-29.png"
                    /></i>
                  </dl>
                  <dl>
                    <i
                      ><img
                        src="https://img.cdn.wxzhida.cn/shzd-web-site/fact-icons/logo-30.png"
                    /></i>
                  </dl>
                  <dl>
                    <i
                      ><img
                        src="https://img.cdn.wxzhida.cn/shzd-web-site/fact-icons/logo-31.png"
                    /></i>
                  </dl>
                  <dl>
                    <i
                      ><img
                        src="https://img.cdn.wxzhida.cn/shzd-web-site/fact-icons/logo-32.png"
                    /></i>
                  </dl>
                  <dl>
                    <i
                      ><img
                        src="https://img.cdn.wxzhida.cn/shzd-web-site/fact-icons/logo-33.png"
                    /></i>
                  </dl>
                  <dl>
                    <i
                      ><img
                        src="https://img.cdn.wxzhida.cn/shzd-web-site/fact-icons/logo-34.png"
                    /></i>
                  </dl>

                  <dl>
                    <i
                      ><img
                        src="https://img.cdn.wxzhida.cn/shzd-web-site/fact-icons/logo-35.png"
                    /></i>
                  </dl>
                  <dl>
                    <i
                      ><img
                        src="https://img.cdn.wxzhida.cn/shzd-web-site/fact-icons/logo-36.png"
                    /></i>
                  </dl>

                  <dl>
                    <i
                      ><img
                        src="https://img.cdn.wxzhida.cn/shzd-web-site/fact-icons/logo-37.png"
                    /></i>
                  </dl>
                </div>
              </div>
            </div>
            <a class="prev" href="javascript:void(0)"></a>
            <a class="next" href="javascript:void(0)"></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCurrentInstance, onMounted, reactive } from "vue";

export default {
  name: "partner",
  components: {},
  setup() {
    const { proxy } = getCurrentInstance();

    onMounted(() => {
      document.title = "挚友合作";

      // 滚动到页面最上方
      window.scrollTo(0, 0);

      // $('.tabBar').slide({
      //   mainCell: '.conWrap',
      //   effect: 'left',
      //   trigger: 'click',
      //   pnLoop: false,
      // })

      var options = {
        useEasing: true,
        useGrouping: true,
        separator: ",",
        decimal: ".",
        prefix: "",
        suffix: "",
      };
      var options2 = {
        useEasing: true,
        useGrouping: true,
        separator: ",",
        decimal: ".",
        decimalPlaces: ".",
        prefix: "",
      };

      // var demo = new CountUp("p_s1", 0, 20.5, 1, 4, options2);
      // var demo2 = new CountUp("p_s2", 0, 80, 0, 2, options);
      // var demo3 = new CountUp("p_s3", 0, 100, 0, 4, options);
      // var demo4 = new CountUp("p_s4", 0, 360, 0, 2, options);
      // var demo6 = new CountUp("p_s6", 0, 140, 0, 2, options);
      // $(window).scroll(function () {
      //   if ($(window).scrollTop() >= 500) {
      //     demo.start();
      //     demo2.start();
      //     demo3.start();
      //     demo4.start();
      //     demo6.start();
      //   }
      // });

      $(window).scroll(function () {
        //为页面添加页面滚动监听事件
        var wst = $(window).scrollTop(); //滚动条距离顶端值
        for (var i = 1; i < 10; i++) {
          if ($("#p" + i) && $("#p" + i).offset()) {
            //加循环
            if ($("#p" + i).offset().top <= wst) {
              //判断滚动条位置
              $("#p" + i).addClass("on"); //给当前导航加c类
            } else {
              //$("#p"+i).removeClass('on');
            }
          }
        }
      });
    });

    return {};
  },
};
</script>

<style scoped>
.margin-right-40 {
  margin-right: 40px;
}
</style>
